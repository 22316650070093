<template>
    <v-select
      v-model="itemId"
      :items="itemDataList"
      :placeholder="myplaceholder"
      item-text="name"
      item-value="id"
      @change="changeItemId()"
      outlined
      clearable
      dense
    >
      <template v-slot:prepend-item>
        <v-list-item
          ripple
        >
          <v-list-item-content>
              <div  class="d-flex justify-content-between mt-n5">
                    <label
                        class="font-size-h6 font-weight-bolder text-dark pt-5"
                        >搜索：{{ myplaceholder }}</label
                    >
                    <span
                        class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                    >（共：{{ totalItem }}条，搜索到：{{ showItem }}条）</span>
              </div>
              <div >
                    <v-text-field 
                        type="input"
                        v-model="itemName"
                        autocomplete="off"
                        @change="searchItem()"
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
              </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
</template>

<style lang="scss">
</style>

<script>
// let that;

  export default {
    name: "LcxSearchSelect",
    data: () => ({
      itemDataList: [],
      itemDataListBak: [],
      itemId: null,
      itemName: null,
      showItem: 0,
      totalItem: 0
    }),

    props: {
      myplaceholder: {
        type: String,
        default: "请输入"
      }
    },

    computed: {
    },

    mounted() {
      // that = this;
      // this.getDataList();
    //   console.log("LcxSearchSelect mounted");
    },
    methods: {
      changeItemId: function() {
        // console.log(this.itemId)
        this.$emit('changeItemId', this.itemId);
      },
      // initItemId: function(v) {
      //     that.itemId = v;
      // },s
      setDataList: function (data) {
        // console.log(data)
        this.itemDataList = data;
        this.itemDataListBak = data;
        this.totalItem = data.length;
        this.showItem = data.length;
      },
      searchItem () {
        this.$nextTick(() => {
            this.showItem = 0;
            this.totalItem = 0;
            if (this.itemName == "") {
                this.showAllList();
            } else {
                this.changeList();
            }
        })
      },
      showAllList: function() {
        for (var item of this.itemDataListBak) {
            item.show = true;
            this.showItem += 1;
            this.totalItem += 1;
        }
        this.itemDataList = this.itemDataListBak.filter(e => {
          return e.show;
        });
      },
      changeList: function() {
        for (var item of this.itemDataListBak) {
            if (item.name.indexOf(this.itemName) != -1) {
                item.show = true;
                this.showItem += 1;
            } else {
                item.show = false;
            }
            this.totalItem += 1;
        }
        this.itemDataList = this.itemDataListBak.filter(e => {
          return e.show;
        });
      },
    }
  }
</script>