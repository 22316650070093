import Vue from "vue";

var Excel = {
    // https://www.cnblogs.com/yixiaoyang-/p/13042540.html
    exportExcel: function(Url, params, FileName) {
        Vue.axios.post(Url, params, {
            responseType: "blob"
        }).then(res => {
            // console.log("res", res);
            if (res) {
                let blob = new Blob([res.data], {
                    type: "application/vnd.ms-excel;charset=utf-8"
                }); // res就是接口返回的文件流了
                let objectUrl = URL.createObjectURL(blob);
                // console.log(objectUrl);
                const elink = document.createElement("a");
                elink.download = FileName; //下载文件名称,
                elink.style.display = "none";
                elink.href = objectUrl;
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }
        });
    }
}

export default Excel;